import { Trans } from '@lingui/macro';

import { TextWithTooltip, TextWithTooltipProps } from '../TextWithTooltip';

export const GasTooltip = ({ ...rest }: TextWithTooltipProps) => {
  return (
    <TextWithTooltip {...rest}>
      <Trans>
        Gas calculation is just an estimate; the transaction price is determined by your wallet. You have the option to
        directly modify gas settings through your wallet provider.
      </Trans>
    </TextWithTooltip>
  );
};
